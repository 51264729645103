const VAT_TAUX_APPORT_BASE = 0.15;
const LOA_TAUX_APPORT_BASE = 0.15;
const LOA_VR_BASE = 0.15;
const LOA_VR_1 = 0.01;
const LOA_VR_10 = 0.1;
const LOA_GARANTIE_BASE = 0;

const fiveYears = 157788000;
const eightYears = 252288000;
const tenYears = 315532800;

const segmentsViaxel = ['B', 'M', 'H'];

const TAUX_TVA = 0.2;

const ORIGIN_DEFAULT = 1;
const ORIGIN_STARTERRE = 2;
const ORIGIN_SITEPRO = 3;
const ORIGIN_SITEPERSO = 4;
const ORIGIN_PICKUP = 5;

const VERSION = 'caab.1024'

class stFinancement {
    constructor(
        prixVehiculeTTC,
        kilometrageVehicule,
        dateImmatriculation,
        garantieConstructeur,
        segmentViaxel,
        avecTVA,
        genre,
        puissanceFiscale: null,
        energie: null,
        eligibiliteLoab: null,
        coeffsVR: null,
        prixConstructeurTTC: null,
        origine: null,
        agrementS3S4: null,
        agrement1erJour: null,
        puissanceDin: null,
        codeEnergie: null,
        elibibiliteDirectDrive : null,
        prixVehiculeTTCHorsFrais : null,
        marque : null,
        ptac: null
    ) {
        this.version = VERSION;
        this.marque = marque !== null && marque !== undefined ? marque.toLowerCase() : null;
        this.ptac = ptac;
        this.prixVehiculeTTC = parseFloat(prixVehiculeTTC);
        this.prixConstructeurTTC = parseFloat(prixConstructeurTTC);
        this.kilometrageVehicule = parseInt(kilometrageVehicule);
        this.dateImmatriculation = dateImmatriculation;
        this.garantieConstructeur = garantieConstructeur;
        this.prixVehiculeTTCHorsFrais = prixVehiculeTTCHorsFrais;

        this.segmentViaxel = segmentViaxel ? segmentViaxel.toUpperCase() : '';
        this.optionsNonEligibles = null;
        this.avecTVA = avecTVA == 1 ? true : false;
        this.genre = genre ? genre.toUpperCase() : '';
        this.puissanceFiscale = puissanceFiscale;

        // Définition this.energie et this.energieCalculOption
        this.getEnergieVehicule(energie, codeEnergie);
        this.codeEnergie = codeEnergie;

        // Définition this.garantieConstructeurRestante
        this.getGarantieConstructeurRestante();

        this.agrementS3S4 = agrementS3S4 != null ? agrementS3S4 : 1;
        this.agrement1erJour = agrement1erJour != null ? agrement1erJour : 1;
        this.eligibiliteLoab = eligibiliteLoab ? eligibiliteLoab : null;
        this.coeffsVR = coeffsVR ? coeffsVR : null;
        this.origine = origine ? origine : ORIGIN_DEFAULT;
        this.puissanceDin = puissanceDin ? puissanceDin : null;

        this.elibibiliteDirectDrive = elibibiliteDirectDrive != null ? elibibiliteDirectDrive : 0;

        this.vxlvat = {
            "eligibiliteVehicule": "ageVehicule < eightYears && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
            "eligibiliteMensualiteBasse": "mensualite >= 12",
            "eligibiliteMensualiteHaute": "mensualite <= 72",
            "eligibiliteApport" : "apport <= (prixVehiculeTTC - 5000)",
            "tauxDeBase" : jsonTauxFinancement['TAUXVXLVAT'] / 100,
            "pourcentFraisDossier" : jsonTauxFinancement['MGMTFEERATE'],
            "taeg" : jsonTauxFinancement['TAEGVXLVAT'],
            "tauxDebiteurFixe" :jsonTauxFinancement['TAUXVXLVAT'],
            "options": {
                // DECES_INVALIDITE - Ex sécurivie
                "DI": {
                    "eligibiliteTitulaire": "ageTitulaire >= eighteenYears && ageTitulaire <= eightyYears",
                    "eligibiliteMontant": "montantTotal <= 160000",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXDIVAT'] / 100,
                    }
                },
                // INDEMNITE COMPLETEMENTAIRE - Ex Sécuricap
                "IC": {
                    "eligibiliteVehicule": "ageVehicule < tenYears && valeurAchatVehicule >= 3500 && valeurAchatVehicule <= 200000 && ptac <= 5000",
                    "eligibiliteTitulare": "ageTitulaire >= eighteenYears",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXICVAT'] / 100,
                        "min": 22,
                        "max": 79
                    }
                },
            }
        };
        this.vxlloa = {
            "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
            "eligibiliteMensualiteBasse": "mensualite >= 25",
            "eligibiliteMensualiteHaute": "mensualite <= 60",
            "eligibiliteApport" : "apport <= (prixVehiculeTTC * 0.35)",
            "taux": jsonTauxFinancement['TAUXVXLLOA'] / 100,
            "options": {
                // DECES_INVALIDITE - Ex sécurivie
                "DI": {
                    "eligibiliteTitulaire": "ageTitulaire >= eighteenYears && ageTitulaire <= eightyYears",
                    "eligibiliteMontant": "montantTotal <= 160000",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXDILOA'] / 100,
                    }
                },
                // INDEMNITE COMPLETEMENTAIRE - Ex Sécuricar
                "IC": {
                    "eligibiliteVehicule": "ageVehicule < tenYears && valeurAchatVehicule >= 3500 && valeurAchatVehicule <= 200000 && ptac <= 5000",
                    "eligibiliteTitulare": "ageTitulaire >= eighteenYears",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXICLOA'] / 100,
                        "min": 22,
                        "max": 79
                    }
                }
            }
        };
        this.vxlloab = {
            "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ((('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP') && repriseMarchand == 0) || repriseMarchand == 1)",
            "eligibiliteMensualiteBasse": "mensualite >= 25",
            "eligibiliteMensualiteHaute": "(mensualite <= 48 && repriseMarchand == 0) || (mensualite <= 60 && repriseMarchand == 1)",
            "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.30)",
            "eligibiliteKilometrage": "(kilometrageTotal <= 80000) || repriseMarchand == 1",
            "tauxBase": jsonTauxFinancement['TAUXVXLLOAB'] / 100,
            "taux": {
                "<=15000": 0.02,
                "<=20000": {
                    "energie": {
                        "essence": {
                            "puissanceFiscale": {
                                "3": 0.058,
                                "4": 0.067,
                                "5": 0.072,
                                "6": 0.086,
                                "7": 0.094,
                                "8": 0.116,
                                "9": 0.132,
                                "10": 0.153,
                                "11": 0.175,
                            }
                        },
                        "diesel": {
                            "puissanceFiscale": {
                                "3": 0.065,
                                "4": 0.074,
                                "5": 0.095,
                                "6": 0.11,
                                "7": 0.121,
                                "8": 0.137,
                                "9": 0.142,
                                "10": 0.164,
                                "11": 0.183,
                            }
                        }
                    }
                },
                ">20000": {
                    "energie": {
                        "essence": {
                            "puissanceFiscale": {
                                "3": 0.106,
                                "4": 0.129,
                                "5": 0.139,
                                "6": 0.166,
                                "7": 0.191,
                                "8": 0.226,
                                "9": 0.257,
                                "10": 0.293,
                                "11": 0.345,
                            }
                        },
                        "diesel": {
                            "puissanceFiscale": {
                                "3": 0.129,
                                "4": 0.152,
                                "5": 0.19,
                                "6": 0.223,
                                "7": 0.24,
                                "8": 0.268,
                                "9": 0.288,
                                "10": 0.323,
                                "11": 0.36,
                            }
                        }
                    }
                }
            },
            "tauxDirectDrive": {
                "<=15000": 0.02,
                ">15000": {
                    "energie": {
                        "essence": {
                            "puissanceFiscale": {
                                "3": 0.058,
                                "4": 0.067,
                                "5": 0.072,
                                "6": 0.086,
                                "7": 0.094,
                                "8": 0.116,
                                "9": 0.132,
                                "10": 0.153,
                                "11": 0.175,
                            }
                        },
                        "electrique": {
                            "puissanceFiscale": {
                                "3": 0.058,
                                "4": 0.067,
                                "5": 0.072,
                                "6": 0.086,
                                "7": 0.094,
                                "8": 0.116,
                                "9": 0.132,
                                "10": 0.153,
                                "11": 0.175,
                            }
                        },
                        "diesel": {
                            "puissanceFiscale": {
                                "3": 0.065,
                                "4": 0.074,
                                "5": 0.095,
                                "6": 0.11,
                                "7": 0.121,
                                "8": 0.137,
                                "9": 0.142,
                                "10": 0.164,
                                "11": 0.183,
                            }
                        }
                    }
                }
            },
        };

        this.provat = {
            "eligibiliteVehicule": "ageVehicule < fiveYears && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
            "eligibiliteMensualiteBasse": "mensualite >= 13",
            "eligibiliteMensualiteHaute": "mensualite <= 72",
            "eligibiliteApport": "apport <= (prixVehiculeTTC - 5000)",
            "tauxDeBase" :  jsonTauxFinancement['TAUXPROVAT'] / 100,
            "pourcentFraisDossier" : jsonTauxFinancement['MGMTFEERATE'],
            "taeg" :  jsonTauxFinancement['TAEGPROVAT'],
            "tauxDebiteurFixe" :  jsonTauxFinancement['TAUXPROVAT'] ,
            "options": {
                // DECES_INVALIDITE - Ex sécurivie
                "DI": {
                    "eligibiliteTitulaire": "ageTitulaire >= eighteenYears && ageTitulaire <= eightyYears",
                    "eligibiliteMontant": "montantTotal <= 160000",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXDIVAT'] / 100,
                    }
                },
                // INDEMNITE COMPLETEMENTAIRE - Ex Sécuricar
                "IC": {
                    "eligibiliteVehicule": "ageVehicule < tenYears && valeurAchatVehicule >= 3500 && valeurAchatVehicule <= 200000 && ptac <= 5000",
                    "eligibiliteTitulare": "ageTitulaire >= eighteenYears",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXICVAT'] / 100,
                        "min": 22,
                        "max": 79
                    }
                }
            }
        };
        this.proloa = {
            "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ('genre' == 'VP' || 'genre' == 'VU' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
            "eligibiliteMensualiteBasse": "mensualite >= 36",
            "eligibiliteMensualiteHaute": "mensualite <= 60",
            "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.25)",
            "taux": jsonTauxFinancement['TAUXPROLOA'] / 100,
            "options": {
                // DECES_INVALIDITE - Ex sécurivie
                "DI": {
                    "eligibiliteTitulaire": "ageTitulaire >= eighteenYears && ageTitulaire <= eightyYears",
                    "eligibiliteMontant": "montantTotal <= 160000",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXDILOA'] / 100,
                    }
                },
                // INDEMNITE COMPLETEMENTAIRE - Ex Sécuricar
                "IC": {
                    "eligibiliteVehicule": "ageVehicule < tenYears && valeurAchatVehicule >= 3500 && valeurAchatVehicule <= 200000 && ptac <= 5000",
                    "eligibiliteTitulare": "ageTitulaire >= eighteenYears",
                    "valeurs": {
                        "coef": jsonTauxFinancement['TAUXICLOA'] / 100,
                        "min": 22,
                        "max": 79
                    }
                }
            }
        };
        this.proloab = {
            "eligibiliteVehicule": "ageVehicule < fiveYears && avecTVA == true && ('genre' == 'VU' || 'genre' == 'VP' || 'genre' == 'CTTE' || 'genre' == 'DERIVE VP')",
            "eligibiliteMensualiteBasse": "mensualite >= 25",
            "eligibiliteMensualiteHaute": "mensualite <= 60",
            "eligibiliteApport": "apport <= (prixVehiculeTTC * 0.30)",
            "tauxBase": jsonTauxFinancement['TAUXPROLOAB'] / 100,
            "tauxDirectDrive": {
                "<=15000": 0.02,
                ">15000": {
                    "energie": {
                        "essence": {
                            "puissanceFiscale": {
                                "3": 0.058,
                                "4": 0.067,
                                "5": 0.072,
                                "6": 0.086,
                                "7": 0.094,
                                "8": 0.116,
                                "9": 0.132,
                                "10": 0.153,
                                "11": 0.175,
                            }
                        },
                        "electrique": {
                            "puissanceFiscale": {
                                "3": 0.058,
                                "4": 0.067,
                                "5": 0.072,
                                "6": 0.086,
                                "7": 0.094,
                                "8": 0.116,
                                "9": 0.132,
                                "10": 0.153,
                                "11": 0.175,
                            }
                        },
                        "diesel": {
                            "puissanceFiscale": {
                                "3": 0.065,
                                "4": 0.074,
                                "5": 0.095,
                                "6": 0.11,
                                "7": 0.121,
                                "8": 0.137,
                                "9": 0.142,
                                "10": 0.164,
                                "11": 0.183,
                            }
                        }
                    }
                }
            },
        };
    }

    /**
     * Cette fonction calcule la valeur actuelle d’un investissement.
     * La valeur actuelle correspond à la somme que représente aujourd’hui un ensemble de remboursements futurs.
     * Par exemple, lorsque vous faites un emprunt, le montant de l’emprunt représente la valeur actuelle pour le prêteur.
     */
    va(taux, npm, vpm, vc = 0, type = 0) {
        if (
            isNaN(parseFloat(taux))
            || isNaN(parseFloat(npm))
            || isNaN(parseFloat(vpm))
            || isNaN(parseFloat(vc))
            || isNaN(parseFloat(type))
        ) {
            return false;
        }

        if (type > 1|| type < 0) {
            return false;
        }

        var tauxAct = Math.pow(1 + taux, -npm);

        if ((1 - tauxAct) == 0) {
            return 0;
        }

        var va = vpm * (1 + taux * type) * (1 - tauxAct) / taux - vc * tauxAct;

        return va;
    }

    /**
     * Cette fonction calcule le remboursement d’un emprunt sur la base de remboursements et d’un taux d’intérêt constants.
     */
    vpm(taux, npm, va, vc = 0, type = 0) {
        if (
            isNaN(parseFloat(taux))
            || isNaN(parseFloat(npm))
            || isNaN(parseFloat(va))
            || isNaN(parseFloat(vc))
            || isNaN(parseFloat(type))
        ) {
            return false;
        }

        if (type > 1 || type < 0) {
            return false;
        }

        var tauxAct = Math.pow(1 + taux, -npm);

        if ((1 - tauxAct) == 0) {
            return 0;
        }

        var vpm = ((va + vc * tauxAct) * taux / (1 - tauxAct) ) / (1 + taux * type);

        return -vpm;
    }

    // Fonction qui détermine si un véhicule est "thermique", "hybride" ou "electrique"
    getEnergieVehicule(energie, codeEnergie)
    {
        codeEnergie = codeEnergie ? codeEnergie : null;
        energie = energie ? energie : '';

        if (codeEnergie !== null) {
            if (codeEnergie === 'EL') {
                this.energieCalculOption = 'electrique';
            } else if (['EE','EH','GL','GH'].indexOf(codeEnergie) !== -1) {
                this.energieCalculOption = 'hybride';
            } else {
                this.energieCalculOption = 'thermique';
            }
        } else {
            if (energie.toLowerCase() === 'electrique') {
                this.energieCalculOption = 'electrique';
            } else if (
                energie.toLowerCase() == 'hybride'
                || energie.toLowerCase() == 'hybride rechargeable'
                || energie.toLowerCase().includes('electrique')
            ) {
                this.energieCalculOption = 'hybride';
            } else {
                this.energieCalculOption = 'thermique';
            }
        }

        if (energie.toLowerCase() == 'hybride') {
            if (codeEnergie !== null && (codeEnergie === 'EE' || codeEnergie === 'EH')) {
                energie = 'essence';
            } else {
                energie = 'diesel';
            }
        }

        this.energie = (energie !== '' && energie.toLowerCase().match(/(essence|diesel|electrique)/g) !== null) ? energie.toLowerCase().match(/(essence|diesel|electrique)/g)[0] : null;
    }

    getGarantieConstructeurRestante() {
        // Calcul du nombre de mois sous garantie constructeur restant
        if (typeof this.dateImmatriculation !== "string") {
            this.garantieConstructeurRestante = 0;
        } else {
            var dateParts = this.dateImmatriculation.split("/");
            var immatriculation = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            immatriculation.setMonth(immatriculation.getMonth()+parseInt(this.garantieConstructeur));

            function monthDiff(d1, d2) {
                var months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth() + 1;
                months += d2.getMonth() + 1;

                if (d1.getDate() > d2.getDate() ) {
                    months -= 1;
                }
                return months <= 0 ? 0 : months;
            }

            var tmpGarantie = monthDiff(new Date(), immatriculation);
            this.garantieConstructeurRestante = isNaN(tmpGarantie) ? 0 : tmpGarantie;
        }
    }

    getAgeVehicule() {
        var regexDate = /(\d+)(-|\/)(\d+)(?:-|\/)(?:(\d+)\s+(\d+):(\d+)(?::(\d+))?(?:\.(\d+))?)?/;
        if (this.dateImmatriculation && regexDate.test(this.dateImmatriculation)) {
            var now = (+new Date())/1000;
            var engDate = this.dateImmatriculation.substr(6, 4)
                + '-' + this.dateImmatriculation.substr(3, 2)
                + '-' + this.dateImmatriculation.substr(0, 2);
            var dateImmat = (+new Date(engDate))/1000;
            var diff = now - dateImmat;
        } else {
            var diff = 0;
        }
        return diff;
    }

    getDefaultApportVAT() {
        if (this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
            return this.prixVehiculeTTC / (1+(TAUX_TVA)) * TAUX_TVA;
        } else {
            return this.prixVehiculeTTC * VAT_TAUX_APPORT_BASE;
        }
    }

    getDefaultApportLOA() {
        if (this.origine == ORIGIN_PICKUP) {
            return this.prixVehiculeTTC * LOA_TAUX_APPORT_BASE;
        }

        if (this.genre == 'VU' || this.genre == 'CTTE' || this.genre == 'DERIVE VP') {
            return this.prixVehiculeTTC / (1+(TAUX_TVA)) * TAUX_TVA;
        } else {
            return this.prixVehiculeTTC * LOA_TAUX_APPORT_BASE;
        }
    }

    setPrixVehicule(prixVehiculeTTC) {
        this.prixVehiculeTTC = parseFloat(prixVehiculeTTC);
    }

    getCapitalEmprunte() {
        return this.prixVehiculeTTC - this.apport;
    }

    // Helper somme d'arrondis
    getRoundSum(arr) {
        var that = this;
        return arr.reduce(function(a,b){
            a = that.roundFloat(a);
            b = that.roundFloat(b);

            return that.roundFloat(a + b);
        }, 0);
    }

    roundFloat(elem) {
        return Math.round(elem * 100) / 100;
    };

    IRRCalc(values, guest = 0.1) {
        // Credits: algorithm inspired by Apache OpenOffice

        // Calculates the resulting amount
        var irrResult = function(values, dates, rate) {
            var r = rate + 1;
            var result = values[0];
            for (var i = 1; i < values.length; i++) {
                result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365);
            }
            return result;
        }

        // Calculates the first derivation
        var irrResultDeriv = function(values, dates, rate) {
            var r = rate + 1;
            var result = 0;
            for (var i = 1; i < values.length; i++) {
                var frac = (dates[i] - dates[0]) / 365;
                result -= frac * values[i] / Math.pow(r, frac + 1);
            }
            return result;
        }

        // Initialize dates and check that values contains at least one positive value and one negative value
        var dates = [];
        var positive = false;
        var negative = false;
        for (var i = 0; i < values.length; i++) {
            dates[i] = (i === 0) ? 0 : dates[i - 1] + 365;
            if (values[i] > 0) positive = true;
            if (values[i] < 0) negative = true;
        }

        // Return error if values does not contain at least one positive value and one negative value
        if (!positive || !negative) return '#NUM!';

        // Initialize guess and resultRate
        var guess = (typeof guess === 'undefined') ? 0.1 : guess;
        var resultRate = guess;

        // Set maximum epsilon for end of iteration
        var epsMax = 1e-10;

        // Set maximum number of iterations
        var iterMax = 50;

        // Implement Newton's method
        var newRate, epsRate, resultValue;
        var iteration = 0;
        var contLoop = true;
        do {
            resultValue = irrResult(values, dates, resultRate);
            newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
            epsRate = Math.abs(newRate - resultRate);
            resultRate = newRate;
            contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax);
        } while(contLoop && (++iteration < iterMax));

        if(contLoop) return '#NUM!';

        // Return internal rate of return
        return resultRate;
    }

    calculTaeg(montantTotalCredit, montantMensualite, duree) {
        var arrayMensualites = [-montantTotalCredit];
        for (var i = 0; i < duree; i++) {
            arrayMensualites.push(montantMensualite);
        }

        var IRRValue = this.IRRCalc(arrayMensualites);

        return (Math.pow(1 + IRRValue, 12) - 1)*100;
    }

    calculTaea(montantTotalCredit, montantMensualite, montantDi, duree) {
        montantDi = montantDi ? montantDi : 0;
        var taeg = this.calculTaeg(montantTotalCredit, montantMensualite, duree);
        var taegSansOption = this.calculTaeg(montantTotalCredit, (montantMensualite - montantDi), duree);

        return taeg - taegSansOption;
    }
}
